import { lazy } from 'react'
import { PrivateRoute } from 'components'
import { PageLayout } from 'components/PageLayout'
import { GlpPath, LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

const GlpRedirectScreen = lazy(() =>
  import('screens/Glp/Redirect').then((module) => ({
    default: module.Redirect
  }))
)

const GlpGoalScreen = lazy(() =>
  import('screens/Glp/Goal').then((module) => ({
    default: module.Goal
  }))
)

const GlpTriedLoseWeightScreen = lazy(() =>
  import('screens/Glp/TriedLoseWeight').then((module) => ({
    default: module.TriedLoseWeight
  }))
)

const GlpNewApproachScreen = lazy(() =>
  import('screens/Glp/NewApproach').then((module) => ({
    default: module.NewApproach
  }))
)

const GlpStateScreen = lazy(() =>
  import('screens/Glp/State').then((module) => ({
    default: module.State
  }))
)

const GlpDateOfBirthScreen = lazy(() =>
  import('screens/Glp/DateOfBirth').then((module) => ({
    default: module.DateOfBirth
  }))
)

const GlpMedicalHistoryScreen = lazy(() =>
  import('screens/Glp/MedicalHistory').then((module) => ({
    default: module.MedicalHistory
  }))
)

const GlpBmiScreen = lazy(() =>
  import('screens/Glp/Bmi').then((module) => ({
    default: module.Bmi
  }))
)

const GlpDescentScreen = lazy(() =>
  import('screens/Glp/Descent').then((module) => ({
    default: module.Descent
  }))
)

const GlpConditionsScreen = lazy(() =>
  import('screens/Glp/Conditions').then((module) => ({
    default: module.Conditions
  }))
)

const GlpWeightLossAmountScreen = lazy(() =>
  import('screens/Glp/WeightLossAmount').then((module) => ({
    default: module.WeightLossAmount
  }))
)

const GlpNotEligibleScreen = lazy(() =>
  import('screens/Glp/NotEligible').then((module) => ({
    default: module.NotEligible
  }))
)

const GlpNotRightProgramScreen = lazy(() =>
  import('screens/Glp/NotRightProgram').then((module) => ({
    default: module.NotRightProgram
  }))
)

const GlpBmiTooLowScreen = lazy(() =>
  import('screens/Glp/BmiTooLow').then((module) => ({
    default: module.BmiTooLow
  }))
)

const GlpSignUpScreen = lazy(() =>
  import('screens/Glp/SignUp').then((module) => ({
    default: module.SignUp
  }))
)

const GlpMissingInformationScreen = lazy(() =>
  import('screens/Glp/MissingInformation').then((module) => ({
    default: module.MissingInformation
  }))
)

const GlpEligibilityNextStepsScreen = lazy(() =>
  import('screens/Glp/EligibilityNextSteps').then((module) => ({
    default: module.EligibilityNextSteps
  }))
)

const GlpShippingScreen = lazy(() =>
  import('screens/Glp/Shipping').then((module) => ({
    default: module.Shipping
  }))
)

const GlpCheckoutScreen = lazy(() =>
  import('screens/Glp/Checkout').then((module) => ({
    default: module.Checkout
  }))
)

const GlpSchedulerScreen = lazy(() =>
  import('screens/Glp/ScheduleCall').then((module) => ({
    default: module.ScheduleCall
  }))
)

const GlpPaymentSuccessfulScreen = lazy(() =>
  import('screens/Glp/PaymentSuccessful').then((module) => ({
    default: module.PaymentSuccessful
  }))
)

const GlpCallScheduledScreen = lazy(() =>
  import('screens/Glp/CallScheduled').then((module) => ({
    default: module.CallScheduled
  }))
)

const GlpCallReScheduledCanceledScreen = lazy(() =>
  import('screens/Glp/CallRescheduledCanceled').then((module) => ({
    default: module.CallRescheduledCanceled
  }))
)

const GlpAllChatsScreen = lazy(() =>
  import('screens/Glp/AllChats').then((module) => ({
    default: module.AllChats
  }))
)

const GlpChatScreen = lazy(() =>
  import('screens/Glp/Chat').then((module) => ({
    default: module.Chat
  }))
)

const GlpAccountScreen = lazy(() =>
  import('screens/Glp/Account').then((module) => ({
    default: module.Account
  }))
)

const GlpDashboardScreen = lazy(() =>
  import('screens/Glp/Dashboard').then((module) => ({
    default: module.Dashboard
  }))
)

const glpMissingInformationPrivate = (
  <PrivateRoute>
    <GlpMissingInformationScreen />
  </PrivateRoute>
)

const glpEligibilityNextStepsPrivate = (
  <PrivateRoute>
    <GlpEligibilityNextStepsScreen />
  </PrivateRoute>
)

const glpSchedulerPrivate = (
  <PrivateRoute>
    <GlpSchedulerScreen />
  </PrivateRoute>
)

const callScheduledPrivate = (
  <PrivateRoute>
    <GlpCallScheduledScreen />
  </PrivateRoute>
)

const callRescheduledCanceledPrivate = (
  <PrivateRoute>
    <GlpCallReScheduledCanceledScreen />
  </PrivateRoute>
)

const glpAllChatsPrivate = (
  <PrivateRoute>
    <GlpAllChatsScreen />
  </PrivateRoute>
)

const glpChatPrivate = (
  <PrivateRoute>
    <GlpChatScreen />
  </PrivateRoute>
)

const glpAccountPrivate = (
  <PrivateRoute>
    <GlpAccountScreen />
  </PrivateRoute>
)

const glpDashboardPrivate = (
  <PrivateRoute>
    <GlpDashboardScreen />
  </PrivateRoute>
)

export const glp: RouteObject = {
  path: LocationPaths.Glp,
  element: <PageLayout />,
  children: [
    {
      index: true,
      element: <GlpRedirectScreen />
    },
    {
      path: GlpPath.Goal,
      element: <GlpGoalScreen />
    },
    {
      path: GlpPath.TriedLoseWeight,
      element: <GlpTriedLoseWeightScreen />
    },
    {
      path: GlpPath.NewApproach,
      element: <GlpNewApproachScreen />
    },
    {
      path: GlpPath.State,
      element: <GlpStateScreen />
    },
    {
      path: GlpPath.DateOfBirth,
      element: <GlpDateOfBirthScreen />
    },
    {
      path: GlpPath.MedicalHistory,
      element: <GlpMedicalHistoryScreen />
    },
    {
      path: GlpPath.Bmi,
      element: <GlpBmiScreen />
    },
    {
      path: GlpPath.Descent,
      element: <GlpDescentScreen />
    },
    {
      path: GlpPath.Conditions,
      element: <GlpConditionsScreen />
    },
    {
      path: GlpPath.WeightLossAmount,
      element: <GlpWeightLossAmountScreen />
    },
    {
      path: GlpPath.Ineligible,
      element: <GlpNotEligibleScreen />
    },
    {
      path: GlpPath.NotRightProgram,
      element: <GlpNotRightProgramScreen />
    },
    {
      path: GlpPath.BmiTooLow,
      element: <GlpBmiTooLowScreen />
    },
    {
      path: GlpPath.SignUp,
      element: <GlpSignUpScreen />
    },
    {
      path: GlpPath.MissingInformation,
      element: glpMissingInformationPrivate
    },
    {
      path: GlpPath.EligibilityNextSteps,
      element: glpEligibilityNextStepsPrivate
    },
    {
      path: GlpPath.Shipping,
      element: <GlpShippingScreen />
    },
    {
      path: GlpPath.Checkout,
      element: <GlpCheckoutScreen />
    },
    {
      path: GlpPath.PaymentSuccessful,
      element: <GlpPaymentSuccessfulScreen />
    },
    {
      path: GlpPath.ScheduleCall,
      element: glpSchedulerPrivate
    },
    {
      path: GlpPath.CallScheduled,
      element: callScheduledPrivate
    },
    {
      path: GlpPath.CallRescheduledCanceled,
      element: callRescheduledCanceledPrivate
    },
    {
      path: GlpPath.AllChats,
      element: glpAllChatsPrivate
    },
    {
      path: GlpPath.Chat,
      element: glpChatPrivate
    },
    {
      path: GlpPath.Account,
      element: glpAccountPrivate
    },
    {
      path: GlpPath.Dashboard,
      element: glpDashboardPrivate
    }
  ]
}
