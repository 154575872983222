import { FC } from 'react'
import { AnimatedPage } from 'screens/VideoCalls/Animated'
import { VideoCallFlow } from 'screens/VideoCalls/types'

export const VideoCallFlowStep: FC<any> = ({ stepIndex }) => {
  const currentStep = VideoCallFlow[stepIndex]
  const StepComponent = currentStep.routeComponent

  if (currentStep.noPageAnimation) {
    return <StepComponent />
  }

  return (
    <AnimatedPage>
      <StepComponent />
    </AnimatedPage>
  )
}
