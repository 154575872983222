import { createContext, useContext } from 'react'
import { Direction } from '../Animated/types'

const defaultValues = {
  stepIndex: -1,
  navigateToNextStep: () => {},
  registerCallback: (_cb: () => void) => {},
  direction: Direction.Forward,
  resetBackDirection: () => {}
}

export const VideoCallFlowContext = createContext(defaultValues)
export const useVideoCallsFlowContext = () => useContext(VideoCallFlowContext)
