import { lazy } from 'react'
import { PageLayout } from 'components/PageLayout'
import { LocationPaths, OwnSensorPath } from 'location.types'
import { RouteObject } from 'react-router-dom'

const OwnSensorRedirectScreen = lazy(() =>
  import('screens/OwnSensor/Redirect').then((module) => ({
    default: module.Redirect
  }))
)

const OwnSensorChooseHealthGoalScreen = lazy(() =>
  import('screens/OwnSensor/ChooseHealthGoal').then((module) => ({
    default: module.ChooseHealthGoal
  }))
)

const OwnSensorWeightLossQuoteScreen = lazy(() =>
  import('screens/OwnSensor/WeightLossQuote').then((module) => ({
    default: module.WeightLossQuote
  }))
)

const OwnSensorMobileOperatingSystemScreen = lazy(() =>
  import('screens/OwnSensor/MobileOperatingSystem').then((module) => ({
    default: module.MobileOperatingSystem
  }))
)

const OwnSensorChooseSensorScreen = lazy(() =>
  import('screens/OwnSensor/ChooseSensor').then((module) => ({
    default: module.ChooseSensor
  }))
)

const OwnSensorEligibilityCheckScreen = lazy(() =>
  import('screens/OwnSensor/EligibilityCheck').then((module) => ({
    default: module.EligibilityCheck
  }))
)

export const ownSensor: RouteObject = {
  path: LocationPaths.OwnSensor,
  element: <PageLayout />,
  children: [
    {
      index: true,
      element: <OwnSensorRedirectScreen />
    },
    {
      path: OwnSensorPath.ChooseHealthGoal,
      element: <OwnSensorChooseHealthGoalScreen />
    },
    {
      path: OwnSensorPath.WeightLossQuote,
      element: <OwnSensorWeightLossQuoteScreen />
    },
    {
      path: OwnSensorPath.MobileOperatingSystem,
      element: <OwnSensorMobileOperatingSystemScreen />
    },
    {
      path: OwnSensorPath.ChooseSensor,
      element: <OwnSensorChooseSensorScreen />
    },
    {
      path: OwnSensorPath.EligibilityCheck,
      element: <OwnSensorEligibilityCheckScreen />
    }
  ]
}
