import { lazy } from 'react'
import { LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

const Auth = lazy(() => import('screens/Auth').then((module) => ({ default: module.Auth })))

const SignInScreen = lazy(() =>
  import('screens/SignIn').then((module) => ({ default: module.SignIn }))
)

const ResetPasswordRequestScreen = lazy(() =>
  import('screens/ResetPasswordRequest').then((module) => ({
    default: module.ResetPasswordRequest
  }))
)

export const auth: RouteObject[] = [
  {
    path: LocationPaths.Auth,
    element: <Auth />
  },
  {
    path: LocationPaths.SignIn,
    element: <SignInScreen />
  },
  {
    path: LocationPaths.PasswordResetRequest,
    element: <ResetPasswordRequestScreen />
  }
]
