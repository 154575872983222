import { LocationPaths, SearchParams } from 'location.types'
import { matchPath } from 'react-router-dom'
import { InsuranceBerryStreetAppointmentType, SurveysConfigKind } from 'types'

export const isVideoCallsFlow = () => {
  return !!matchPath({ path: LocationPaths.VideoCalls, end: false }, window.location.pathname)
}

export const surveyScreenPath = (
  surveyKind: SurveysConfigKind,
  options: { fallback?: string } = {}
) => {
  const { fallback } = options
  const queryString = fallback ? `?${SearchParams.SurveyFallbackUrl}=${fallback}` : ''

  return `${LocationPaths.Questionnaire.replace(':surveyKind', surveyKind)}${queryString}`
}

export const redirectToSurveyScreen = (surveyKind: SurveysConfigKind) => {
  const screenHref = surveyScreenPath(surveyKind)
  if (isVideoCallsFlow()) {
    window.location.replace(`${LocationPaths.VideoCalls}${screenHref}`)
    return
  }
  window.location.replace(screenHref)
}

interface ScheduleCallParams {
  appointmentType?: InsuranceBerryStreetAppointmentType | null | undefined
  pendingAppointmentId?: string | undefined
  coachId?: string | undefined
  previousSlotNotAvailable?: boolean | undefined
}

export const redirectToScheduleCallScreen = (params: ScheduleCallParams = {}) => {
  const searchParams = new URLSearchParams(window.location.search)
  if (params.appointmentType) {
    searchParams.set('appointmentType', params.appointmentType)
  }
  if (params.pendingAppointmentId) {
    searchParams.set('pendingAppointmentId', params.pendingAppointmentId)
  }
  if (params.coachId) {
    searchParams.set('coachId', params.coachId)
  }
  if (params.previousSlotNotAvailable) {
    searchParams.set('previousSlotNotAvailable', 'true')
  }
  const screenPath = `${LocationPaths.ScheduleCall}?${searchParams.toString()}`

  if (isVideoCallsFlow()) {
    window.location.href = `${LocationPaths.VideoCalls}${screenPath}`
    return
  }
  window.location.href = screenPath
}
