import React from 'react'
import { Slide } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useVideoCallsFlowContext } from 'screens/VideoCalls/context/VideoCallFlowContext'
import { styles } from './styles'
import { TRANSITION_DURATION } from './types'

export const AnimatedPage = ({
  children,
  key
}: {
  children: React.ReactElement<any, any>
  key?: string
}) => {
  const { direction, resetBackDirection } = useVideoCallsFlowContext()
  const location = useLocation()

  const containerRef = React.useRef(null)

  return (
    <Slide
      direction={direction}
      key={`${location.key}-${key}`}
      in
      timeout={TRANSITION_DURATION}
      mountOnEnter
      unmountOnExit
      addEndListener={(node, done) => {
        done()
        resetBackDirection()
      }}
      container={containerRef.current}
    >
      <div style={styles.animatedContent}>{children}</div>
    </Slide>
  )
}
