import { NotFound } from 'components/NotFound'
import { LocationPaths } from 'location.types'
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom'
import { App } from './App'
import { auth, dpp, glp, ownSensor, root, videoCalls } from './routes'

const routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <Navigate to={LocationPaths.HealthQuestionnaire} replace />
      },
      ...auth,
      dpp,
      glp,
      videoCalls,
      ownSensor,
      root,
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]

export const AppRouter = createBrowserRouter(routes)
