import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, Divider, Stack, Theme, IconButton } from '@mui/material'
import { User as UserIcon } from '@phosphor-icons/react'
import { useAuth } from 'context'
import { GlpPath, LocationPaths } from 'location.types'
import { Link } from 'react-router-dom'
import { useReferralPartner } from 'screens/VideoCalls/hooks/useReferralPartner'

export interface HeaderProps {
  showSignOut?: boolean
  showProfile?: boolean
  hideBorder?: boolean
  backButton?: () => void
}

export const Header = (props: HeaderProps) => {
  const { showSignOut, showProfile, hideBorder = false } = props
  const { logoUrl } = useReferralPartner()
  const { signOut, isAuthorized } = useAuth()

  return (
    <Box sx={[styles.container, !hideBorder && styles.containerBorder]}>
      {props.backButton && (
        <Button
          startIcon={<ArrowBackIosIcon fontSize="inherit" />}
          onClick={props.backButton}
          sx={styles.backButton}
          variant="text"
          size="small"
        >
          Back
        </Button>
      )}
      <Stack
        sx={[styles.logos, props.backButton ? styles.spaceLeft : {}]}
        divider={<Divider orientation="vertical" sx={styles.logoSeparator} flexItem />}
      >
        {logoUrl && (
          <Box component="img" src={logoUrl} alt="Partner Logo" sx={styles.partnerLogo} />
        )}
        <Box component="img" src="/logo.svg" alt="Nutrisense Logo" sx={styles.logo} />
      </Stack>
      {isAuthorized && (showProfile || showSignOut) && (
        <Box sx={styles.buttonGroup}>
          {showProfile && (
            <IconButton
              sx={styles.profileButton}
              size="small"
              component={Link}
              to={LocationPaths.Glp + '/' + GlpPath.Account}
            >
              <UserIcon weight="bold" />
            </IconButton>
          )}
          {showSignOut && (
            <Button sx={styles.logoutButton} variant="text" size="small" onClick={signOut}>
              Logout
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

const styles = {
  container: (theme: Theme) => ({
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateAreas: `
      "left logo right"
    `,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'grid',
    py: 3,
    px: 0.5,

    [theme.breakpoints.up('sm')]: {
      px: 2
    }
  }),
  containerBorder: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: 'brandPrimary.light.main'
  },
  logos: (theme: Theme) => ({
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    gridArea: 'logo',
    minHeight: theme.components?.MuiButton?.styleOverrides?.sizeSmall?.height || 20,
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  }),
  spaceLeft: (theme: Theme) => ({
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 0
    }
  }),
  logo: (theme: Theme) => ({
    justifySelf: 'center',
    maxWidth: '94px',
    gridColumn: '2',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '116px'
    }
  }),
  partnerLogo: (theme: Theme) => ({
    maxWidth: '90px',
    width: '100%',
    maxHeight: '30px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '112px'
    }
  }),
  logoSeparator: (theme: Theme) => ({
    margin: 'auto 0.75rem',
    height: 28,
    [theme.breakpoints.up('md')]: {
      margin: 'auto 1.5rem',
      height: 35
    }
  }),
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'end',
    gridArea: 'right',
    justifyContent: 'flex-end',
    flexGrow: 1
  },
  profileButton: {
    color: 'brandPrimary.light.main',
    fontSize: '0.9375rem',
    letterSpacing: '-0.01em'
  },
  backButton: {
    color: 'brandPrimary.light.main',
    letterSpacing: '-0.01em',
    fontSize: '0.9375rem',
    justifySelf: 'start',
    gridArea: 'left'
  },
  logoutButton: {
    color: 'brandPrimary.light.main',
    letterSpacing: '-0.01em',
    fontSize: '0.9375rem',
    justifySelf: 'end',
    gridArea: 'right'
  }
}
