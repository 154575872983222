import { useCallback, useRef } from 'react'
import { app } from 'config'
import { analytics } from 'config/segment'
import isEqual from 'lodash/isEqual'

export const usePageTracking = () => {
  const currentPage = useRef('')
  const currentAttributes = useRef({})

  const trackPage = useCallback(
    (nextPage: string, pageAttributes?: Record<string, string | number | boolean>) => {
      // remove trailing slash
      const normalizedPage = nextPage.replace(/\/$/, '')

      if (
        currentPage.current === normalizedPage &&
        isEqual(currentAttributes.current, pageAttributes)
      ) {
        return
      }

      currentPage.current = normalizedPage
      currentAttributes.current = pageAttributes || {}
      analytics.page(normalizedPage, pageAttributes || {})

      if (!app.analyticsEnabled) {
        // eslint-disable-next-line no-console
        console.debug('trackPage', normalizedPage, pageAttributes)
      }
    },
    []
  )

  return trackPage
}
