import { lazy } from 'react'
import { PrivateRoute } from 'components'
import { PageLayout } from 'components/PageLayout'
import { DppPath, LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

const DppRedirectScreen = lazy(() =>
  import('screens/Dpp/Redirect').then((module) => ({
    default: module.Redirect
  }))
)

const DppChatScreen = lazy(() =>
  import('screens/Dpp/Chat').then((module) => ({
    default: module.Chat
  }))
)

const DppDashboardScreen = lazy(() =>
  import('screens/Dpp/Dashboard').then((module) => ({
    default: module.Dashboard
  }))
)

const DppFlowCompletedScreen = lazy(() =>
  import('screens/Dpp/FlowCompleted').then((module) => ({
    default: module.FlowCompleted
  }))
)

const DppJoinCohortScreen = lazy(() =>
  import('screens/Dpp/Cohorts/JoinCohort').then((module) => ({
    default: module.JoinCohort
  }))
)

const DppFlowCohortWaitlistScreen = lazy(() =>
  import('screens/Dpp/Cohorts/Waitlist').then((module) => ({
    default: module.Waitlist
  }))
)

const DppNotEligibleOutcomeScreen = lazy(() =>
  import('screens/Dpp/NotEligibleOutcome').then((module) => ({
    default: module.NotEligibleOutcome
  }))
)

const DppNotEligibleVideoCallsOutcomeScreen = lazy(() =>
  import('screens/Dpp/NotEligibleVideoCallsOutcome').then((module) => ({
    default: module.NotEligibleVideoCallsOutcome
  }))
)

const dppChatPrivate = (
  <PrivateRoute>
    <DppChatScreen />
  </PrivateRoute>
)

const dppDashboardPrivate = (
  <PrivateRoute>
    <DppDashboardScreen />
  </PrivateRoute>
)

const dppFlowCompletedPrivate = (
  <PrivateRoute>
    <DppFlowCompletedScreen />
  </PrivateRoute>
)

const dppJoinCohortPrivate = (
  <PrivateRoute>
    <DppJoinCohortScreen />
  </PrivateRoute>
)

const dppCohortWaitlistPrivate = (
  <PrivateRoute>
    <DppFlowCohortWaitlistScreen />
  </PrivateRoute>
)

export const dpp: RouteObject = {
  path: LocationPaths.Dpp,
  element: <PageLayout />,
  children: [
    {
      index: true,
      element: <DppRedirectScreen />
    },
    {
      path: DppPath.Chat,
      element: dppChatPrivate
    },
    {
      path: DppPath.Dashboard,
      element: dppDashboardPrivate
    },
    {
      path: DppPath.Completed,
      element: dppFlowCompletedPrivate
    },
    {
      path: DppPath.JoinCohort,
      element: dppJoinCohortPrivate
    },
    {
      path: DppPath.CohortWaitlist,
      element: dppCohortWaitlistPrivate
    },
    {
      path: DppPath.Ineligible,
      element: <DppNotEligibleOutcomeScreen />
    },
    {
      path: DppPath.IneligibleVideoCalls,
      element: <DppNotEligibleVideoCallsOutcomeScreen />
    }
  ]
}
