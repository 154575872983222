import { User } from 'types'
import { GoogleTagManagerUser } from './types'

let sha256: (email: string) => Promise<string>

if (typeof crypto !== 'undefined' && crypto.subtle) {
  // Use Web Crypto API for modern browsers
  sha256 = async function (email: string) {
    const encoder = new TextEncoder()
    const data = encoder.encode(email)
    const hashBuffer = await crypto.subtle.digest('SHA-256', data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  }
} else {
  // Fallback to crypto-js for older browsers
  sha256 = async function (email: string) {
    const cryptoSha256 = (await import('crypto-js/sha256')).default
    return cryptoSha256(email).toString()
  }
}

export const transformIntoGoogleTagManagerUser = async (
  user: Partial<User>
): Promise<GoogleTagManagerUser> => {
  const email = user.email?.trim().toLowerCase() || ''
  // we don't want sha256 of an empty string
  const userEmailSha256 = email ? await sha256(email) : ''
  const phoneNumber = user.phoneNumber
  const phoneNumberSha256 = phoneNumber ? sha256(phoneNumber).toString() : undefined

  return {
    userEmail: email,
    userFirstName: user.firstName,
    userLastName: user.lastName,
    userPhoneNumber: phoneNumber,
    userZipCode: user.address?.zipCode,
    userEmailSha256: userEmailSha256,
    userPhoneNumberSha256: phoneNumberSha256,
    userAddressStreet: user.address?.street,
    userAddressCity: user.address?.city,
    userAddressState: user.address?.state
  }
}
