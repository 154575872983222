import { useReactiveVar } from '@apollo/client'
import { Box, LinearProgress, linearProgressClasses } from '@mui/material'
import { CurrentSurvey, currentSurveyVar } from 'screens/Survey/currentSurveyVar'
import { useVideoCallsFlowContext } from 'screens/VideoCalls/context/VideoCallFlowContext'
import { SurveysConfigKind } from 'types'
import { VideoCallFlow } from './types'

const surveyProgress = (currentSurvey: CurrentSurvey) => {
  if (currentSurvey.kind !== SurveysConfigKind.Insurance) {
    return 0
  }

  return currentSurvey.questionIndex / currentSurvey.length
}

export const FlowProgress = () => {
  const { stepIndex } = useVideoCallsFlowContext()
  const totalStepsCount = VideoCallFlow.length
  const currentSurvey = useReactiveVar(currentSurveyVar)
  const insuranceSurveyProgress = surveyProgress(currentSurvey)
  let progress = (stepIndex + insuranceSurveyProgress + 1) / totalStepsCount
  // Ensure that progress can't be greater than 1 so the progress bar doesn't overflow
  progress = Math.min(progress, 1)

  return (
    <Box>
      <LinearProgress variant="determinate" value={progress * 100} sx={styles.progressBar} />
    </Box>
  )
}

const styles = {
  progressBar: {
    height: '6px',
    width: '100%',
    backgroundColor: '#E3E1DE',
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: 'brandPrimary.light.main',
      borderBottomRightRadius: 5,
      borderTopRightRadius: 5
    }
  }
}
