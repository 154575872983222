import { lazy } from 'react'
import { PrivateRoute } from 'components'
import { PageLayout } from 'components/PageLayout'
import { LocationPaths } from 'location.types'
import { RouteObject } from 'react-router-dom'

const SurveyScreen = lazy(() =>
  import('screens/Survey').then((module) => ({
    default: module.SurveyScreen
  }))
)

const SensorSelectionScreen = lazy(() =>
  import('screens/SensorSelection').then((module) => ({
    default: module.SensorSelection
  }))
)

const FreeVideoCallScreen = lazy(() =>
  import('screens/FreeVideoCall').then((module) => ({
    default: module.FreeVideoCall
  }))
)

const ScheduleCallScreen = lazy(() =>
  import('screens/ScheduleCall').then((module) => ({
    default: module.ScheduleCall
  }))
)

const CheckoutScreen = lazy(() =>
  import('screens/Checkout').then((module) => ({
    default: module.Checkout
  }))
)

const surveysPrivate = (
  <PrivateRoute>
    <SurveyScreen />
  </PrivateRoute>
)

const surveysPublic = <SurveyScreen />

const sensorSelectionPrivate = (
  <PrivateRoute>
    <SensorSelectionScreen />
  </PrivateRoute>
)

const freeVideoCallPrivate = (
  <PrivateRoute>
    <FreeVideoCallScreen />
  </PrivateRoute>
)

const scheduleCallPrivate = (
  <PrivateRoute>
    <ScheduleCallScreen />
  </PrivateRoute>
)

const checkoutPrivate = (
  <PrivateRoute>
    <CheckoutScreen />
  </PrivateRoute>
)

export const root: RouteObject = {
  element: <PageLayout />,
  children: [
    {
      path: LocationPaths.HealthQuestionnaire,
      element: surveysPrivate
    },
    {
      path: LocationPaths.Questionnaire,
      element: surveysPublic
    },
    {
      path: LocationPaths.SensorSelection,
      element: sensorSelectionPrivate
    },
    {
      path: LocationPaths.FreeVideoCall,
      element: freeVideoCallPrivate
    },
    {
      path: LocationPaths.ScheduleCall,
      element: scheduleCallPrivate
    },
    {
      path: LocationPaths.Checkout,
      element: checkoutPrivate
    }
  ]
}
