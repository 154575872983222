import {
  Cloud,
  DotsThree,
  ForkKnife,
  GenderFemale,
  Heartbeat,
  PersonArmsSpread,
  Pulse,
  Syringe,
  Virus,
  Grains,
  HandHeart
} from '@phosphor-icons/react'
import ScaleIcon from 'components/Icons/ScaleIcon'

export enum HealthGoalName {
  WeightLoss = 'weight_loss',
  MindfulEating = 'mindful_eating',
  GutHealth = 'gut_health',
  InsulinResistance = 'insulin_resistance',
  AthleticPerformance = 'athletic_performance',
  WomensHealth = 'womens_health',
  AutoimmuneDisease = 'autoimmune_disease',
  ChronicFatigue = 'chronic_fatigue',
  HeartDisease = 'heart_disease',
  FoodAllergies = 'food_allergies',
  ManagePreDiabetes = 'manage_pre_diabetes',
  Other = 'other'
}

export const HEALTH_GOALS = [
  {
    label: 'Weight loss',
    value: HealthGoalName.WeightLoss,
    Icon: ScaleIcon
  },
  {
    label: 'Mindful Eating/Intuitive eating',
    value: HealthGoalName.MindfulEating,
    Icon: ForkKnife
  },
  {
    label: 'Gut Health',
    value: HealthGoalName.GutHealth,
    Icon: Cloud
  },
  {
    label: 'Insulin Resistance',
    value: HealthGoalName.InsulinResistance,
    Icon: Syringe
  },
  {
    label: 'Athletic Performance',
    value: HealthGoalName.AthleticPerformance,
    Icon: PersonArmsSpread
  },
  {
    label: 'Women’s Health (Menopause, PCOS, Fertility)',
    value: HealthGoalName.WomensHealth,
    Icon: GenderFemale
  },
  {
    label: 'Autoimmune disease',
    value: HealthGoalName.AutoimmuneDisease,
    Icon: Virus
  },
  {
    label: 'Chronic fatigue/Low energy levels',
    value: HealthGoalName.ChronicFatigue,
    Icon: Pulse
  },
  {
    label: 'Heart disease',
    value: HealthGoalName.HeartDisease,
    Icon: Heartbeat
  },
  {
    label: 'Intolerances/Food Allergies',
    value: HealthGoalName.FoodAllergies,
    Icon: Grains
  },
  {
    label: 'Manage Pre Diabetes/Diabetes',
    value: HealthGoalName.ManagePreDiabetes,
    Icon: HandHeart
  },
  { label: 'Other', value: HealthGoalName.Other, Icon: DotsThree }
]
